import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button } from 'domains/web/components';
import {
  CategoryItemContainer,
  CategoryItem,
  CategoryItemImage,
  CategoryItemText,
  CategoryContainer,
  DesktopViewAllCategoryItemText,
  DesktopViewAllCategoryContainer,
} from './Styled';
import getHomepageAnalytics from '../../getHomepageAnalytics';

type Props = {
  categories: Array<{
    categoryName: string;
    imageUrl: string;
    path: string;
    desktopOnly?: boolean;
  }>;
  homepageAnalytics: ReturnType<typeof getHomepageAnalytics>;
  leftAlign?: boolean;
  rowIndex: number;
  rowKey: string;
  title: ReactNode;
};

const HomepageCategories = ({
  categories,
  homepageAnalytics,
  leftAlign,
  rowKey,
  rowIndex,
  title,
}: Props) => {
  const handleCategoryItemClick = ({ categoryName, index }) => {
    homepageAnalytics.trackCategoryCircleClick({
      categoryName,
      index,
      rowKey,
      rowIndex,
    });
  };

  const onViewAllClick = () => {
    handleCategoryItemClick({
      categoryName: 'view-all',
      index: categories.length,
    });
  };

  return (
    <div>
      {title || null}
      <CategoryContainer leftAlign={leftAlign}>
        {categories.map((category, index) => {
          const onClick = () => {
            handleCategoryItemClick({
              categoryName: category.categoryName,
              index,
            });
          };

          return (
            <CategoryItemContainer
              desktopOnly={category?.desktopOnly}
              key={category.categoryName}
            >
              <CategoryItem onClick={onClick} as={Link} to={category.path}>
                <CategoryItemImage
                  loading="lazy"
                  src={category.imageUrl}
                  alt={category.categoryName}
                />
              </CategoryItem>
              <CategoryItemText onClick={onClick}>
                {category.categoryName}
              </CategoryItemText>
            </CategoryItemContainer>
          );
        })}
        <DesktopViewAllCategoryContainer
          onClick={onViewAllClick}
          as={Link}
          to="/browse"
        >
          <DesktopViewAllCategoryItemText>
            {'View all'}
          </DesktopViewAllCategoryItemText>
        </DesktopViewAllCategoryContainer>
      </CategoryContainer>
      <Box
        display={{ _: 'block', lg: 'none' }}
        maxWidth={{ _: 'none', sm: '47rem' }}
        mx="auto"
      >
        <Button
          as={Link}
          onClick={onViewAllClick}
          size="medium"
          to="/browse"
          variant="secondary"
          width="100%"
        >
          {'View all'}
        </Button>
      </Box>
    </div>
  );
};

export default HomepageCategories;
