import React from 'react';
import { Link } from 'react-router-dom';
import { Text, Box, Button, Image } from 'domains/web/components';
import {
  AMERICAN_GREETINGS_BANNER,
  FATHERS_DAY_BANNER,
  MARKETING_BANNER,
  MOTHERS_DAY_BANNER,
  SUMMER_BANNER,
  VALENTINES_BANNER,
} from './consts';

export const globalUseMarketingBanner = true;

export enum BannerVariant {
  default = 'default',
  americanGreetings = 'american_greetings',
  valentines = 'valentines_day',
  mothersDay = 'mothers_day',
  fathersDay = 'fathers_day',
  summer = 'summer',
}

// this controls timed hide / show logic

interface Props {
  hideButton?: boolean;
  variant?: BannerVariant;
}

export const MarketingBanner = ({ hideButton, variant }: Props) => {
  let banner = null;
  let showTerms = false;

  if (globalUseMarketingBanner) {
    switch (variant) {
      case BannerVariant.americanGreetings: {
        banner = AMERICAN_GREETINGS_BANNER;
        showTerms = false;
        break;
      }
      case BannerVariant.valentines: {
        banner = VALENTINES_BANNER;
        showTerms = false;
        break;
      }
      case BannerVariant.mothersDay: {
        banner = MOTHERS_DAY_BANNER;
        showTerms = false;
        break;
      }
      case BannerVariant.fathersDay: {
        banner = FATHERS_DAY_BANNER;
        showTerms = false;
        break;
      }
      case BannerVariant.summer: {
        banner = SUMMER_BANNER;
        showTerms = false;
        break;
      }
      default: {
        banner = MARKETING_BANNER;
        showTerms = false;
      }
    }
  }

  if (!banner) {
    return null;
  }

  return (
    <Box
      p={4}
      borderStyle="solid"
      borderColor="border.default"
      borderWidth="1px"
      borderRadius={{ _: 4 }}
      display="flex"
      minHeight="240px"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      backgroundSize="cover"
      backgroundColor="black"
      position="relative"
      backgroundPosition="center"
      background={`url(https://www.cameo.com/cdn-cgi/image/fit=cover,format=auto,width=2880/https://cdn.cameo.com/resizer/${banner.imageKey})`}
    >
      {Boolean(banner.title) && (
        <Box
          borderRadius={{ _: 4 }}
          opacity={0.25}
          backgroundColor="black"
          position="absolute"
          height="100%"
          width="100%"
        />
      )}
      <Box
        zIndex={2}
        textAlign="center"
        display="flex"
        minHeight="240px"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        maxWidth={{ sm: '630px' }}
      >
        <Text variant={{ _: 'title_m', sm: 'title_l' }} mb={2} as="h1">
          {banner.title}
        </Text>
        <Text variant="base_l" mb={hideButton ? 0 : 4}>
          {banner.subtitle}{' '}
        </Text>{' '}
        {!hideButton && (
          <Button
            as={Link}
            to={banner.button.link}
            size="medium"
            variant="secondary"
          >
            {banner.button.text}
          </Button>
        )}
        {showTerms && (
          <Text
            variant="base_xxs"
            mt={{ _: 6, lg: 8 }}
            color="foreground.muted"
          >
            {`Exclusions and limitations apply, see Cameo's Terms for details.`}
          </Text>
        )}
      </Box>
      {Boolean(banner.secondaryImage) && (
        <Box display="flex" mb={4}>
          <Image
            alt={'Logo'}
            imageUrlKey={banner.secondaryImage}
            width={180}
            height={24}
            resizerWidth={270}
            resizerHeight={36}
          />
        </Box>
      )}
    </Box>
  );
};
