import CollectionView from 'components/BrowseRow/CollectionView';
import React from 'react';
import {
  CarouselRow,
  CollectionRow,
} from 'types/src/api/endpoints/explore/browseRow';
import { LayoutsResponse } from 'types/src/api/endpoints/explore/endpoints/layouts';
import { Text } from 'domains/web/components';
import { useLocation } from 'react-router-dom';
import { utmParamsParser } from 'analytics/utils/utmParamsParser';
import getHomepageAnalytics from './getHomepageAnalytics';
import { H2HackWrapper, SectionTitle } from './Styled';
import { SectionType } from './types';
import SectionWrapper from './SectionWrapper';
import ThisIsCameoSection from './ThisIsCameoSection';
import HomepageReviewsRow from './components/HomepageReviewsRow';
import HomepageCategories from './components/HomepageCategories';
import HowItWorksSection from './HowItWorksSection';
import CarouselRowSection from './CarouselRowSection';
import {
  BOTTOM_CATEGORIES,
  INSPO_CAROUSEL_UK,
  PRICE_BUCKET,
  TOP_HEADER_CATEGORIES,
  TOP_HEADER_CATEGORIES_UK,
  FANTASTY_INSPO_CAROUSEL,
} from './consts';
import { SocialBanner } from './components/SocialBanner';
import {
  BannerVariant,
  MarketingBanner,
  globalUseMarketingBanner,
} from './components/MarketingBanner';

type Props = {
  homepageAnalytics: ReturnType<typeof getHomepageAnalytics>;
  index: number;
  layoutData: LayoutsResponse;
  sectionType: SectionType;
  country: string;
};

const Section = ({
  homepageAnalytics,
  index,
  layoutData,
  sectionType,
  country,
}: Props) => {
  const isUk = country === 'GB' || country === 'UK';
  const priceBucketItem = PRICE_BUCKET as CarouselRow;
  let inspoCarouselItem;
  if (isUk) {
    inspoCarouselItem = INSPO_CAROUSEL_UK as CarouselRow;
  } else {
    inspoCarouselItem = FANTASTY_INSPO_CAROUSEL as CarouselRow;
  }

  const { search } = useLocation();
  const { utmSource, utmCampaign } = utmParamsParser(search);
  const useMarketingBanner =
    globalUseMarketingBanner &&
    utmCampaign === 'american_greetings' &&
    utmSource === 'affiliate';

  switch (sectionType) {
    case SectionType.SOCIAL_BANNER: {
      return <SocialBanner />;
    }

    case SectionType.BANNER: {
      if (!useMarketingBanner) {
        return null;
      }

      const variant =
        utmCampaign === 'american_greetings' && utmSource === 'affiliate'
          ? BannerVariant.americanGreetings
          : BannerVariant.default;

      return (
        <SectionWrapper
          homepageAnalytics={homepageAnalytics}
          index={index}
          noDivider
          rowKey="marketing-banner"
        >
          <MarketingBanner hideButton variant={variant} />
        </SectionWrapper>
      );
    }
    case SectionType.CATEGORIES:
      return (
        <SectionWrapper
          homepageAnalytics={homepageAnalytics}
          index={index}
          noDivider
          rowKey="categories-circles"
        >
          <HomepageCategories
            categories={isUk ? TOP_HEADER_CATEGORIES_UK : BOTTOM_CATEGORIES}
            homepageAnalytics={homepageAnalytics}
            leftAlign
            rowIndex={index}
            rowKey={`categories-circles-bottom-${country}}`}
            title={<SectionTitle>{'Categories'}</SectionTitle>}
          />
        </SectionWrapper>
      );

    case SectionType.HEADER_CATEGORIES: {
      return (
        <SectionWrapper
          homepageAnalytics={homepageAnalytics}
          index={index}
          noDivider
          rowKey="categories-circles-header"
        >
          <HomepageCategories
            categories={isUk ? TOP_HEADER_CATEGORIES_UK : TOP_HEADER_CATEGORIES}
            homepageAnalytics={homepageAnalytics}
            rowIndex={index}
            rowKey={`categories-circles-top-${country}}`}
            title={
              !useMarketingBanner ? (
                <Text
                  as="h1"
                  variant="title_l"
                  textAlign="center"
                  textWrap="balance"
                  mb={7}
                >
                  {'Personalized videos from your favorite stars'}
                </Text>
              ) : (
                <SectionTitle>{'Shop by category'}</SectionTitle>
              )
            }
          />
        </SectionWrapper>
      );
    }

    case SectionType.HOW_IT_WORKS:
      return (
        <SectionWrapper
          homepageAnalytics={homepageAnalytics}
          index={index}
          isHorizontallyScrollable
          noDivider
          rowKey="how-it-works"
        >
          <HowItWorksSection homepageAnalytics={homepageAnalytics} />
        </SectionWrapper>
      );

    case SectionType.MARKETING_CAROUSEL: {
      const marketingCarousel = layoutData?.pageContent.items.find(
        (item) =>
          item.key === 'Homepage-marketing-carousel' && item.type === 'carousel'
      ) as CarouselRow;

      if (marketingCarousel) {
        return (
          <SectionWrapper
            homepageAnalytics={homepageAnalytics}
            index={index}
            isHorizontallyScrollable
            noDivider
            rowKey="Homepage-marketing-carousel"
          >
            <CarouselRowSection rowIndex={index} source={marketingCarousel} />
          </SectionWrapper>
        );
      }

      return null;
    }

    case SectionType.MARKETING_SPOTLIGHT: {
      return null;
    }

    case SectionType.PRICES:
      return priceBucketItem ? (
        <SectionWrapper
          homepageAnalytics={homepageAnalytics}
          index={index}
          isHorizontallyScrollable
          noDivider
          rowKey="prices"
        >
          <CarouselRowSection rowIndex={index} source={priceBucketItem} />
        </SectionWrapper>
      ) : null;

    case SectionType.INSPO_CAROUSEL:
      return inspoCarouselItem ? (
        <SectionWrapper
          homepageAnalytics={homepageAnalytics}
          index={index}
          isHorizontallyScrollable
          noDivider
          rowKey="featured-picks"
        >
          <CarouselRowSection rowIndex={index} source={inspoCarouselItem} />
        </SectionWrapper>
      ) : null;

    case SectionType.RECENTLY_VIEWED: {
      const recentlyViewedItem = layoutData?.pageContent.items.find(
        (item) => item.key === 'recentlyViewedShelf_001'
      ) as CollectionRow;

      if (recentlyViewedItem) {
        return (
          <SectionWrapper
            homepageAnalytics={homepageAnalytics}
            index={index}
            noDivider
            rowKey="recently-viewed"
          >
            <H2HackWrapper>
              <CollectionView
                isCollectionV2
                source={recentlyViewedItem}
                rowIndex={index}
                preloadStarcardImages
                headerVariant="h2"
                key={recentlyViewedItem.key}
              />
            </H2HackWrapper>
          </SectionWrapper>
        );
      }

      return null;
    }

    case SectionType.LEADERBOARD: {
      const talentLeaderboardShelf = layoutData?.pageContent.items.find(
        (item) => item.key === 'talent-leaderboard'
      ) as CollectionRow;

      if (talentLeaderboardShelf) {
        return (
          <SectionWrapper
            homepageAnalytics={homepageAnalytics}
            index={index}
            noDivider
            rowKey="talent-leaderboard"
          >
            <H2HackWrapper>
              <CollectionView
                isCollectionV2
                isV2StarcardEnabled
                source={talentLeaderboardShelf}
                rowIndex={index}
                preloadStarcardImages
                headerVariant="h2"
                key={talentLeaderboardShelf.key}
              />
            </H2HackWrapper>
          </SectionWrapper>
        );
      }

      return null;
    }

    case SectionType.REVIEWS:
      return (
        <SectionWrapper
          homepageAnalytics={homepageAnalytics}
          index={index}
          isHorizontallyScrollable
          noDivider
          rowKey="reviews"
        >
          <HomepageReviewsRow />
        </SectionWrapper>
      );

    case SectionType.THIS_IS_CAMEO:
      return (
        <SectionWrapper
          homepageAnalytics={homepageAnalytics}
          index={index}
          noDivider
          rowKey="this-is-cameo"
        >
          <ThisIsCameoSection
            title={<SectionTitle>{'This is Cameo'}</SectionTitle>}
          />
        </SectionWrapper>
      );
    default:
      return null;
  }
};

export default Section;
