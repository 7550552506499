import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Footer } from 'components';
import { helmetJsonLdProp } from 'react-schemaorg';
import { WebSite } from 'schema-dts';
import { getUserGeoData } from 'state/modules/users/selectors';
import { LayoutsResponse } from 'types/src/api/endpoints/explore/endpoints/layouts';
import { useQuery } from '@tanstack/react-query';
import { Box } from 'domains/web/components';
import getHomepageAnalytics from './getHomepageAnalytics';
import { Root, Wrapper } from './Styled';
import Section from './Section';
import { SectionType } from './types';
import { getHomepageLayout } from './queries';

const getSectionTypes = (hasRecentlyViewed: boolean) => {
  if (hasRecentlyViewed) {
    return [
      SectionType.BANNER,
      SectionType.HEADER_CATEGORIES,
      SectionType.INSPO_CAROUSEL,
      SectionType.SOCIAL_BANNER,
      SectionType.RECENTLY_VIEWED,
      SectionType.LEADERBOARD,
      SectionType.MARKETING_SPOTLIGHT,
      SectionType.MARKETING_CAROUSEL,
      SectionType.PRICES,
      SectionType.HOW_IT_WORKS,
      SectionType.REVIEWS,
      SectionType.CATEGORIES,
      SectionType.THIS_IS_CAMEO,
    ];
  }

  return [
    SectionType.BANNER,
    SectionType.HEADER_CATEGORIES,
    SectionType.INSPO_CAROUSEL,
    SectionType.LEADERBOARD,
    SectionType.SOCIAL_BANNER,
    SectionType.MARKETING_SPOTLIGHT,
    SectionType.HOW_IT_WORKS,
    SectionType.MARKETING_CAROUSEL,
    SectionType.PRICES,
    SectionType.REVIEWS,
    SectionType.CATEGORIES,
    SectionType.THIS_IS_CAMEO,
  ];
};

const HomepageV2 = () => {
  const metaTags = [];
  const homepageAnalytics = getHomepageAnalytics();

  const result = useQuery<LayoutsResponse>(getHomepageLayout());

  const layoutData = result.data;

  // use recently viewed to determine if someone is considered "new"
  const hasRecentlyViewed = Boolean(
    layoutData?.pageContent.items.find(
      (item) => item.key === 'recentlyViewedShelf_001'
    )
  );

  const sectionTypes = useMemo(
    () => getSectionTypes(hasRecentlyViewed),
    [hasRecentlyViewed]
  );

  const geoLoc = useSelector(getUserGeoData);
  const country = geoLoc?.country?.iso_code;

  useEffect(() => {
    homepageAnalytics.trackLayoutView();
  }, []);

  return (
    <>
      <Helmet
        titleTemplate="Cameo - %s"
        title="Personalized videos feat. your favorite stars"
        meta={metaTags}
        script={[
          helmetJsonLdProp<WebSite>({
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            url: 'https://www.cameo.com',
            potentialAction: {
              // @ts-ignore it's a valid property
              '@type': 'SearchAction',
              target: 'https://www.cameo.com/search?q={query}',
              // @ts-ignore it's a valid property
              'query-input': 'required name=query',
            },
          }),
        ]}
        link={[
          {
            rel: 'canonical',
            href: 'https://www.cameo.com',
          },
        ]}
      />
      <Box backgroundColor="background.level0" position="relative" zIndex={1}>
        <Root id="homepage-root">
          <Wrapper>
            {sectionTypes.map((sectionType, index) => (
              <Section
                homepageAnalytics={homepageAnalytics}
                index={index}
                key={sectionType}
                layoutData={layoutData}
                sectionType={sectionType}
                country={country}
              />
            ))}
          </Wrapper>
        </Root>
      </Box>
      <Footer renderSticker />
    </>
  );
};

export default HomepageV2;
