import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { H3, Caption, ThisIsCameoWrapper } from './Styled';

const thisIsCameoSectionData = (t) => {
  return {
    items: [
      {
        title: t(
          'ThisIsCameo.items.1.title',
          'Gifts as unique as the people you’re gifting to'
        ),
        caption: t(
          'ThisIsCameo.items.1.caption-updated',
          'Every video is personalized for the person receiving it, creating one-of-a-kind connections between celebrities and the people they inspire.'
        ),
      },
      {
        title: t(
          'ThisIsCameo.items.2.title',
          'Perfect for every occasion (or just because)'
        ),
        caption: t(
          'ThisIsCameo.items.2.caption-updated',
          'From birthdays to holidays and friendly roasts, Cameo is here to help you bring magic into everyday moments both big and small.'
        ),
      },
      {
        title: t('ThisIsCameo.items.3.title', 'Someone for every fan'),
        caption: t(
          'ThisIsCameo.items.3.caption-updated',
          'Everyone is welcome here. With over forty-thousand celebrities, there’s a star for every kind of fan on Cameo.'
        ),
      },
    ],
  };
};

type Props = {
  title: ReactNode;
};

const ThisIsCameoSection = ({ title }: Props) => {
  const { t } = useTranslation();

  const data = thisIsCameoSectionData(t);

  return (
    <div>
      {title || null}
      <ThisIsCameoWrapper>
        {data.items.map((item) => (
          <div key={item.title}>
            <H3>{item.title}</H3>
            <Caption>{item.caption}</Caption>
          </div>
        ))}
      </ThisIsCameoWrapper>
    </div>
  );
};

export default ThisIsCameoSection;
