export const VALENTINES_TAG = 'valentines-day-deals-2024';

export const MARKETING_BANNER = {
  imageKey: 'e10e09b1-78f3-4307-98f4-f069b6d8e0fc.jpg',
  title: 'Personalized videos from your favorite stars',
  subtitle: null,
};

export const AMERICAN_GREETINGS_BANNER = {
  imageKey: '87f0332b-6f8c-4544-a217-3005e95001c2.jpg',
  title: 'Personalized videos from your favorite stars',
  secondaryImage: 'b77ee11f-0d54-420f-af5b-23a569c19cf9.png',
  subtitle: null,
};

export const VALENTINES_BANNER = {
  imageKey: '45ff910d-2e66-40e9-94a2-ffbad704182e.jpg',
  title: '',
  subtitle: null,
};

export const MOTHERS_DAY_BANNER = {
  imageKey: 'e9b30e48-28ed-4235-93b3-d1bca7ce495f.jpg',
  title: '',
  subtitle: null,
};

export const FATHERS_DAY_BANNER = {
  imageKey: '20bc8529-ed62-414b-b20e-a47e3642a68e.jpg',
  title: '',
  subtitle: null,
};

export const SUMMER_BANNER = {
  imageKey: 'c72d8d01-3b85-45fc-a86a-3646d9daea37.jpg',
  title: '',
  subtitle: null,
};
