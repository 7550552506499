import styled from 'styled-components';
import { sizing, media } from 'design-system/style';

export const Root = styled.div`
  display: flex;
  flex-direction: column;

  padding: ${sizing(24)} ${sizing(20)};
  background-color: #201e23;

  @media screen and (min-width: ${media.queries.TABLET}) {
    padding: ${sizing(48)} ${sizing(32)};
    margin-left: ${sizing(-54)};
    margin-right: ${sizing(-54)};
  }

  @media screen and (min-width: ${media.queries.DESKTOP}) {
    border-radius: ${sizing(24)};
    margin: 0;
  }
`;
export const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: ${sizing(20)};
  line-height: ${sizing(28)};
  text-align: center;
  color: #f2f1f3;
  margin-bottom: ${sizing(24)};

  @media screen and (min-width: ${media.queries.TABLET}) {
    font-size: ${sizing(28)};
    line-height: ${sizing(40)};
    margin-bottom: ${sizing(32)};
  }
`;
export const CategoryItem = styled.a`
  display: block;
  border-radius: ${sizing(1000)};
  margin-bottom: ${sizing(8)};
  overflow: hidden;

  background: #201e23;
  border: 1px solid #f2f1f3;
  -webkit-transform: translateZ(0);

  &:hover {
    cursor: pointer;
  }

  height: ${sizing(95)};
  width: ${sizing(95)};

  @media screen and (min-width: 375px) {
    height: ${sizing(106)};
    width: ${sizing(106)};
  }

  @media screen and (min-width: ${media.queries.DESKTOP}) {
    height: ${sizing(120)};
    width: ${sizing(120)};
  }

  @media screen and (min-width: ${media.queries.LARGE_DESKTOP}) {
    height: ${sizing(146)};
    width: ${sizing(146)};
  }
`;

export const CategoryItemImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: ${sizing(1000)};
  transform: scale(1);
  transition-duration: 400ms;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.17, 0.67, 0.13, 1.02);
  -webkit-transform: translateZ(0);
`;

export const CategoryContainer = styled.div<{ leftAlign?: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: ${sizing(16)};

  margin: auto;
  max-width: ${sizing(420)};
  margin-bottom: ${sizing(24)};

  @media screen and (min-width: 720px) {
    margin: unset;
    max-width: inherit;
    margin-bottom: ${sizing(24)};
  }

  @media screen and (min-width: ${media.queries.TABLET}) {
    justify-content: ${({ leftAlign }) => (leftAlign ? 'left' : 'center')};
  }

  @media screen and (min-width: ${media.queries.DESKTOP}) {
    margin-bottom: 0;
  }
`;

export const CategoryItemText = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: ${sizing(14)};
  line-height: ${sizing(20)};
  text-decoration: underline;

  text-align: center;

  color: #f2f1f3;

  &:hover {
    cursor: pointer;
  }
`;

export const DesktopViewAllCategoryItemText = styled.div`
  border-radius: ${sizing(1000)};
  margin-bottom: ${sizing(8)};

  border: 1px solid #f2f1f3;
  -webkit-transform: translateZ(0);

  font-style: normal;
  font-weight: 500;
  font-size: ${sizing(14)};
  line-height: ${sizing(20)};

  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: underline;

  color: #f2f1f3;

  &:hover {
    cursor: pointer;
  }

  height: ${sizing(95)};
  width: ${sizing(95)};

  @media screen and (min-width: 375px) {
    height: ${sizing(106)};
    width: ${sizing(106)};
  }

  @media screen and (min-width: ${media.queries.DESKTOP}) {
    height: ${sizing(120)};
    width: ${sizing(120)};
  }

  @media screen and (min-width: ${media.queries.LARGE_DESKTOP}) {
    height: ${sizing(146)};
    width: ${sizing(146)};
  }
`;

export const CategoryItemContainer = styled.div<{ desktopOnly?: boolean }>`
  ${({ desktopOnly }) => (desktopOnly ? 'display: none;' : '')};

  &:hover {
    ${CategoryItemText},
    ${DesktopViewAllCategoryItemText} {
      color: ${({ theme }) => theme.colors.foreground.muted};
    }

    ${CategoryItem},
    ${DesktopViewAllCategoryItemText} {
      border-color: ${({ theme }) => theme.colors.foreground.muted} !important;
    }

    ${CategoryItemImage} {
      transform: scale(1.1);
    }
  }

  @media screen and (min-width: ${media.queries.TABLET}) {
    margin-right: ${sizing(16)};

    &:last-child {
      margin-right: 0;
    }
  }

  @media screen and (min-width: 1440px) {
    ${({ desktopOnly }) => (desktopOnly ? 'display: block;' : '')};
  }
`;

export const DesktopViewAllCategoryContainer = styled.a`
  display: none;

  &:hover {
    ${DesktopViewAllCategoryItemText} {
      border-color: ${({ theme }) => theme.colors.foreground.muted} !important;
      color: ${({ theme }) => theme.colors.foreground.muted} !important;
    }
  }

  @media screen and (min-width: ${media.queries.DESKTOP}) {
    display: block;
  }
`;
