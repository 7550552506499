import React, { createContext, useContext, useMemo } from 'react';

export type BrowseOverridesContextValue = {
  browsePrefix: string;
};

const BrowseOverridesContext =
  createContext<BrowseOverridesContextValue | null>(null);

const ContextFallback = {
  browsePrefix: '',
};

export const useBrowseOverrides =
  (): Partial<BrowseOverridesContextValue> => {
    const ctx = useContext(BrowseOverridesContext);

    return ctx ?? ContextFallback;
  };

export const BrowseOverridesProvider = ({
  browsePrefix = '',
  children,
}: {
  browsePrefix?: string;
  children?: React.ReactNode;
}) => {
  const ctx = useMemo(() => {
    const value: BrowseOverridesContextValue = {
      browsePrefix,
    };

    return value;
  }, [browsePrefix]);

  return (
    <BrowseOverridesContext.Provider value={ctx}>
      {children}
    </BrowseOverridesContext.Provider>
  );
};
