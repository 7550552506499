import React, { PropsWithChildren } from 'react';
import styled, { StyledComponent } from 'styled-components';
import { variant } from 'styled-system';
import { useTranslation } from 'react-i18next';
import { config } from 'cameoConfig';

import { Box, Button, Text, TextProps } from 'domains/web/components';
import { ThemeInterface } from 'domains/web/theme/types';
import analytics from 'analytics';

export const SocialBanner = () => {
  const { t } = useTranslation('SocialBanner');
  // TODO Optimizely to eppo. Check for dead code removal.
  const isExperimentEnabled = false;

  if (!isExperimentEnabled) return null;

  const onClickCTA = () => {
    const data = {
      eventName: 'Engage',
      eventDtl: {
        eventType: 'Click',
      },
      objectDtl: {
        objectType: 'Banner',
        containerType: 'Banner',
      },
      positionDtl: {
        row: 0,
        rowLabel: 'SocialSearchBanner',
      },
      sourceDtl: {
        sourceType: 'Home',
      },
    } as const;

    analytics.trackAction(data);
  };

  return (
    <Container>
      <Box
        display="grid"
        gridTemplateColumns={{
          _: '75%',
          md: '12rem 3fr 15rem',
          lg: '13rem 3fr 18rem',
        }}
        gridGap={3}
        position="relative"
      >
        <Box display={{ _: 'none', md: 'block' }}>
          {/*
            This empty div allow us to shift from mobile to desktop layout
            by filling the first column of the parent grid template
          */}
        </Box>
        <Box display="grid" gridGap={1}>
          <ResponsiveText
            fontScale="title"
            color="foreground.default"
            fontWeight="medium"
          >
            {t('title', "Find your friends' faves on Cameo")}
          </ResponsiveText>
          <ResponsiveText
            fontScale="body"
            color="foreground.muted"
            fontWeight="regular"
          >
            {t(
              'description',
              'See which Cameo celebs your friends follow using their social handles.'
            )}
          </ResponsiveText>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          width={{ _: '60%', md: '100%' }}
          ml={{ _: 0, md: 'auto' }}
        >
          <Button
            as={'a'}
            variant="secondary"
            size="small"
            width="100%"
            href={`${config.website}/social-search`}
            onClick={onClickCTA}
            target="_blank"
          >
            {'Get started'}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

const Container = ({ children }: PropsWithChildren<object>) => {
  return (
    <Box
      paddingX={{ _: 5, md: 6 }}
      paddingTop={{ _: 4, md: 3, lg: 6 }}
      paddingBottom={{ _: 4, md: 3, lg: 6 }}
      backgroundColor="background.level2"
      borderRadius={{ _: 0, md: 3 }}
      position="relative"
      overflow="hidden"
      my={{ _: 9, md: 10 }}
    >
      <Box
        as="img"
        position="absolute"
        display={{ _: 'block', md: 'none' }}
        right="0"
        top="0"
        src="https://d3el26csp1xekx.cloudfront.net/static/assets/right.svg"
        alt="Find the perfect gift"
        bottom="3rem"
      />
      <Box
        as="img"
        position="absolute"
        display={{ _: 'none', md: 'block' }}
        left="0"
        top="0"
        bottom="0"
        src="https://d3el26csp1xekx.cloudfront.net/static/assets/left.svg"
        alt="Find the perfect gift"
        width="15rem"
      />
      {children}
    </Box>
  );
};

type ResponsiveTextProps = Omit<TextProps, 'variant'> & {
  fontScale: 'title' | 'body';
  children: React.ReactNode;
};

/**
 * Responsive text is not something we currently support in our design system.
 * If this becomes a common pattern, we should consider to add it by default.
 * And perhaps add aliases to do like ['base_s', 'base_l', 'base_l']
 */

const ResponsiveText: StyledComponent<
  'p',
  ThemeInterface,
  ResponsiveTextProps,
  never
> = styled(Text)`
  ${({ theme: t }) => {
    const theme = t as unknown as ThemeInterface;

    return variant({
      prop: 'fontScale',
      /**
       * For some reason the API {_: x, md: y, lg: z} is not working as expected
       * in this case, so we need to repeat the values for each breakpoint.
       */
      variants: {
        title: {
          fontSize: [
            theme.fonts.fontSizes.base_s,
            theme.fonts.fontSizes.base_s,
            theme.fonts.fontSizes.title_s,
          ],
          lineHeight: [
            theme.fonts.lineHeights.base_s,
            theme.fonts.lineHeights.base_s,
            theme.fonts.lineHeights.title_s,
          ],
        },
        body: {
          fontSize: [
            theme.fonts.fontSizes.base_s,
            theme.fonts.fontSizes.base_s,
            theme.fonts.fontSizes.base_l,
          ],
          lineHeight: [
            theme.fonts.lineHeights.base_s,
            theme.fonts.lineHeights.base_s,
            theme.fonts.lineHeights.base_l,
          ],
        },
      },
    });
  }}
`;
