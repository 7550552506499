import analytics from 'analytics';

const getHomepageAnalytics = () => {
  const trackCategoryCircleClick = ({
    categoryName,
    index,
    rowKey,
    rowIndex,
  }) => {
    analytics.trackAction({
      eventName: 'Navigate',
      eventDtl: {
        eventType: 'Click',
      },
      objectDtl: {
        objectType: 'Card',
        containerType: 'Basic-Shelf',
      },
      positionDtl: {
        rowLabelText: categoryName,
        rowKey,
        col: index,
        row: rowIndex,
      },
      sourceDtl: {
        sourceType: 'Home',
        sourceValue: categoryName,
      },
    });
  };

  const trackLayoutView = () => {
    const data = {
      eventName: 'View' as const,
      eventDtl: {
        contents: {
          layoutKey: 'homepage-redesign',
        },
      },
      objectDtl: {
        objectType: 'Layout' as const,
      },
    };

    analytics.trackSystem(data);
  };

  const trackSectionInView = ({ index, rowKey }) => {
    analytics.trackSystem({
      eventName: 'View' as const,
      objectDtl: {
        objectType: 'Shelf' as const,
        objectVersion: rowKey,
        containerType: undefined,
      },
      positionDtl: {
        col: 0,
        row: index,
        rowKey,
        rowLabelText: undefined,
        rowLabelType: undefined,
      },
      sourceDtl: {
        sourceId: rowKey,
        sourceKey: undefined,
        sourceType: 'Home' as const,
        sourceUrl: 'www.cameo.com',
        sourceValue: rowKey,
      },
    });
  };

  const trackReactionVideoClick = ({ index, rowKey, rowIndex, username }) => {
    analytics.trackAction({
      eventName: 'Navigate',
      eventDtl: {
        eventType: 'Click',
      },
      objectDtl: {
        objectType: 'Card',
        containerType: 'Basic-Shelf',
      },
      positionDtl: {
        rowLabelText: username,
        rowKey,
        col: index,
        row: rowIndex,
      },
      sourceDtl: {
        sourceType: 'Home',
        sourceValue: username,
      },
    });
  };

  const trackLearnMoreClick = () => {
    analytics.trackAction({
      eventName: 'Navigate',
      eventDtl: {
        eventType: 'Click',
      },
      objectDtl: {
        objectType: 'Text',
        containerType: 'Basic-Shelf',
      },
      sourceDtl: {
        sourceType: 'Home',
        sourceValue: 'How It Works',
      },
    });
  };

  return {
    trackCategoryCircleClick,
    trackLayoutView,
    trackLearnMoreClick,
    trackReactionVideoClick,
    trackSectionInView,
  };
};

export default getHomepageAnalytics;
